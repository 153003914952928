import { ThemeProvider } from '@emotion/react';
import GlobalNav from '@hulu/web-ui/GlobalNav';
import {
  oneHuluLightTheme,
  oneHuluDarkTheme,
} from '@hulu/web-ui/style/createTheme';
import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import Cookie from 'js-cookie';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { scroller } from 'react-scroll';

import navigationMessages from '../messages';
import { NavigationModelSchema } from '../model/schema';

import Toaster from './Toaster';

import { view as LoginModal } from '!app/components/LoginModal';
import { mobileAppUrls } from '!app/config';
import { Translate } from '!app/i18n';
import { preventFocus } from '!app/lib/accessibilityUtils';
import { CMS_PAGETYPE, BREAKPOINTS, BROWSE } from '!app/lib/constants';
import { mobileDetect, isBrowser } from '!app/lib/environment';
import { useWindowResize } from '!app/lib/hooks/useWindowResize';
import {
  getCtaText,
  generateLogin,
  getPremiumNetworkFromParameter,
} from '!app/lib/signupUtils';
import { getEventNameFromEntitlement } from '!app/lib/TealiumEventsUtils';
import {
  ctaRedirect,
  isLanguage,
  shouldUseConnectedLogin,
  useConnectedLoginDetectAndRedirect,
} from '!app/lib/urlUtils';
import { getName } from '!app/lib/userUtils';
import { normalize } from '!app/lib/utils';
import { SCROLL_QUARTER_PERCENTILE } from '!app/metrics/constants';
import { fireUserInteraction } from '!app/metrics/fireEvent';
import { withUserInteraction, withUtagLink } from '!app/metrics/hoc';
import { checkPreviousQuarters } from '!app/metrics/utils';
import CTAButton from '!app/share/CTAButton';
import messages from '!app/share/messages';
import { DetailEntityStoreSchema } from '!app/share/schema';
import '../stylesheet/NavigationStyle.scss';

const TOPHAT_HEIGHT = 74;
const FOOTER_HEIGHT = 100;

const THROTTLE_WAIT_TIME = 250;
const PERCENTAGE_MULTIPLIER = 100;

/**
 * Handle the actions when the user attempts to log into an existing Hulu account.
 *
 * @param toggleModal Callback function to toggle on a modal with the specified ID.
 * @param md A MobileDetect object that returns if the user is on a mobile device.
 * @param user The user data from the app's global state.
 * @param featureFlags The feature flags data from the app's global state.
 * @param loginLink The URL of the standalone Hulu login page.
 */
export const handleLogin = ({
  toggleModal,
  md,
  user,
  featureFlags,
  loginLink,
}) => {
  const { isHuluUser } = user;

  if (
    !md.mobile() &&
    !(user && isHuluUser) &&
    !shouldUseConnectedLogin(featureFlags?.hasConnectedAuthEnabled)
  ) {
    const hasEdnaLoginEnabled = featureFlags?.hasEdnaLoginEnabled ?? false;
    if (hasEdnaLoginEnabled) {
      window.location.assign(loginLink);
    } else {
      toggleModal('login-modal');
    }
  } else if (shouldUseConnectedLogin(featureFlags?.hasConnectedAuthEnabled)) {
    // If a device should use the connected login experience instead of the standard
    // login form. Redirect them to the connected app login experience.
    useConnectedLoginDetectAndRedirect();
  } else {
    window.location.assign(loginLink);
  }
};

const Navigation = (props) => {
  const {
    model: {
      ctaDownloadAppText,
      cta_always,
      cta,
      cta_button_style,
      ctaHypeProgram,
      disable_logo,
      items,
      signup_flow_entry,
      enable_cta_toaster,
      enableMinimalNav,
      enableStickyModeAlways,
      ...restOfModel
    },
    user,
    asPath,
    locale,
    ctaFields,
    topHatShown,
    pageType,
    cartAbandonment,
    featureFlags,
    host,
  } = props;

  const isStartPage =
    asPath.toLowerCase().includes('/start/') ||
    asPath.toLowerCase() === '/start';
  const isDetailsPage =
    asPath.toLowerCase().includes('/series/') ||
    asPath.toLowerCase().includes('/movie/');
  const md = mobileDetect();
  const os = md.os();
  const deviceAppUrl = mobileAppUrls[os];
  const isDevice = Boolean(deviceAppUrl);
  const translator = new Translate(locale);
  const translatedMessages = translator.translateAll({
    ...messages,
    ...navigationMessages,
  });
  const { log_in, cta_manage, get_app } = translatedMessages;
  const { isHuluUser } = user;
  const shouldShowDownloadAppLink =
    isHuluUser && md.mobile() && ctaDownloadAppText;

  // Due to the confict between entitlement checks and translation, this check is required.
  const isEsLang = isLanguage('es-us');
  const stickyMode = restOfModel.sticky_mode;
  const [ctaActive, setCtaActive] = useState(false);
  const [active, setActive] = useState(false);
  const [positionBottom, setPositionBottom] = useState(false);
  const [userName, setUserName] = useState(get(user, 'name', null));
  const loginLink = generateLogin({
    isHuluUser,
    asPath,
    url: host,
  });
  const isTransparent = !((active && stickyMode) || enableStickyModeAlways);
  const isLoggedIn = isHuluUser && userName;
  const ctaText = shouldShowDownloadAppLink
    ? ctaDownloadAppText
    : getCtaText({
        user,
        componentNonSubCta: cta,
        componentSubCta: isEsLang && cta_manage,
        locale,
        ctaFields,
        cartAbandonment,
      });
  // no cta on start page or mobile details page
  let shouldRenderCta = true;
  if (isDevice && isDetailsPage) {
    shouldRenderCta = false;
  }
  const {
    windowSize: { width },
  } = useWindowResize();
  const isMobileView = width ? width < BREAKPOINTS.LARGE : null;

  const ActionButton = withUserInteraction(
    isLoggedIn ? 'span' : 'a',
    'navigation',
    isHuluUser ? 'account_page' : 'login'
  );
  const scrollThreshold = useRef(0);
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener(
        'scroll',
        throttle(onScroll, THROTTLE_WAIT_TIME),
        true
      );
    }
    onNavMounted();
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [topHatShown]);

  const onNavMounted = async () => {
    const cookie = Cookie.get();
    if (!userName) {
      const name = await getName(cookie);
      setUserName(name);
    }
  };

  /**
   * Handle onscroll event. Calculate states based on scroll position.
   *
   */
  const onScroll = () => {
    const wintop = global.window.pageYOffset;
    const startPointOfStickyMode = topHatShown ? TOPHAT_HEIGHT : 1;
    const scrollHeight = global.document.body.scrollHeight;
    const innerHeight = global.window.innerHeight;
    if (wintop > startPointOfStickyMode) {
      setActive(true);
    } else {
      setActive(false);
    }

    const TOP_OFFSET = 80;
    const masthead =
      global.document.getElementById('masthead') ||
      global.document.getElementById('HeroSliderMasthead__hero');
    if (masthead !== null) {
      if (masthead.offsetHeight - TOP_OFFSET <= wintop) {
        setCtaActive(true);
      } else {
        setCtaActive(false);
      }
    }
    if (enable_cta_toaster) {
      const position = scrollHeight - FOOTER_HEIGHT < wintop + innerHeight;
      setPositionBottom(position);
    }

    // HUWEB-30044: once a user reaches a quarter, HIT will fire and the threshold
    // gets updated to the next quarter until they reach the bottom
    const currentPageScrollPercent = Math.round(
      ((wintop + innerHeight) / scrollHeight) * PERCENTAGE_MULTIPLIER
    );
    if (currentPageScrollPercent > scrollThreshold.current) {
      if (currentPageScrollPercent === SCROLL_QUARTER_PERCENTILE[4]) {
        // reached bottom of page
        checkPreviousQuarters(scrollThreshold.current, 4);
        fireUserInteraction('default', 'scroll:100', 'swipe', false);
        scrollThreshold.current = SCROLL_QUARTER_PERCENTILE[4];
      } else if (currentPageScrollPercent >= SCROLL_QUARTER_PERCENTILE[3]) {
        // reached fourth quarter
        checkPreviousQuarters(scrollThreshold.current, 3);
        fireUserInteraction('default', 'scroll:75', 'swipe', false);
        scrollThreshold.current = SCROLL_QUARTER_PERCENTILE[4] - 1;
      } else if (currentPageScrollPercent >= SCROLL_QUARTER_PERCENTILE[2]) {
        // reached third quarter
        checkPreviousQuarters(scrollThreshold.current, 2);
        fireUserInteraction('default', 'scroll:50', 'swipe', false);
        scrollThreshold.current = SCROLL_QUARTER_PERCENTILE[3];
      } else if (currentPageScrollPercent >= SCROLL_QUARTER_PERCENTILE[1]) {
        // reached second quarter
        checkPreviousQuarters(scrollThreshold.current, 1);
        fireUserInteraction('default', 'scroll:25', 'swipe', false);
        scrollThreshold.current = SCROLL_QUARTER_PERCENTILE[2];
      } else {
        // reached first quarter
        fireUserInteraction('default', 'scroll:0', 'swipe', false);
        scrollThreshold.current = SCROLL_QUARTER_PERCENTILE[1];
      }
    }
  };

  const scrollTo = (e, id) => {
    e.preventDefault();
    if (!id) return;
    if (id.startsWith('#')) {
      scroller.scrollTo(id.replace('#', ''), {
        duration: 500,
        delay: 100,
        smooth: true,
        offset: -76,
        activeClass: 'active',
      });
    } else {
      window.location = id;
    }
  };

  const handleLoginButtonClick = (event) => {
    event.preventDefault();

    const { toggleModal } = props;
    handleLogin({ toggleModal, md, user, featureFlags, loginLink });
  };

  /**
   * Redirects user to signup or to download app
   * @param {string} entry Signup flow URL
   */
  const getCtaRedirect = (entry) => {
    const { requirePremium, network } = props;

    const from =
      ctaHypeProgram || getPremiumNetworkFromParameter(requirePremium, network);

    const ctaOptions = {
      user,
      componentUrl: entry,
      ctaFields,
      from,
      cartAbandonment,
      passUrlAsProgram: pageType !== BROWSE,
    };

    if (shouldShowDownloadAppLink) {
      window.location.assign(deviceAppUrl);
    } else {
      ctaRedirect(ctaOptions);
    }
  };

  const getNavItems = () => {
    let navItems = [];

    const NavItemUtagOptions = {
      event_name: 'navigate_inpage',
      navigation_method: 'navigation',
    };

    if (items && !isEmpty(items)) {
      navItems = items?.map((item, index) => {
        const finalUtagOptions = {
          navigation_target: item.text,
          ...NavItemUtagOptions,
        };
        const NavItem = withUtagLink(
          finalUtagOptions,
          withUserInteraction('a', 'navigation', normalize(item.text))
        );
        return (
          <NavItem
            key={index}
            onClick={(e) => scrollTo(e, item.url)}
            href={item.url}
            className="navigation__item"
            data-automationid={`navigation_item_${normalize(item.text)}`}
          >
            {item.text}
          </NavItem>
        );
      });
    }

    if (isDevice && !isStartPage) {
      navItems.push(
        <a
          key="get-app"
          className="navigation__item navigation__item--transparent navigation__item--mobile-only"
          href={deviceAppUrl}
          onMouseDown={preventFocus}
          role="button"
          aria-label="Learn more"
          tabIndex="0"
        >
          {get_app}
        </a>
      );
    }

    if (isMobileView) {
      if (!isHuluUser) {
        navItems.push(
          <ActionButton
            key="action"
            className="navigation__item navigation__item--transparent"
            onClick={handleLoginButtonClick}
            href={loginLink}
            data-automationid="navigation_login_button"
          >
            <Text variant="label14">{log_in}</Text>
          </ActionButton>
        );
      }
      navItems.push(
        <CTA key="cta-in-menu" className="navigation__cta--in-menu" />
      );
    }

    return navItems;
  };

  const CTA = ({ className, emphasis = 'high', size = 'large' }) => {
    const DriverButton = withUtagLink(
      {
        event_name: getEventNameFromEntitlement(
          user,
          shouldShowDownloadAppLink
        ),
        cta_placement: 'navigation',
      },
      withUserInteraction(CTAButton, 'cta_nav')
    );
    return (
      <DriverButton
        emphasis={emphasis}
        size={size}
        className={className}
        useStyle={cta_button_style}
        onClick={() => getCtaRedirect(signup_flow_entry)}
        data-automationid={
          className === 'navigation__toaster-cta'
            ? 'toaster_cta'
            : 'navigation_cta'
        }
        mode={isTransparent ? 'dark' : 'light'}
      >
        {ctaText}
      </DriverButton>
    );
  };

  const Login = ({ onLoginButtonClicked }) => {
    return (
      <ActionButton
        key="action"
        className={classNames('navigation__login-button', {
          'navigation__logged-out-button': !isLoggedIn,
        })}
        onClick={onLoginButtonClicked}
        href={loginLink}
        data-automationid="navigation_login_button"
      >
        {isLoggedIn ? (
          <GlobalNav.ProfileNavButton text={userName?.charAt(0)} />
        ) : (
          <Text variant="label14">{log_in}</Text>
        )}
      </ActionButton>
    );
  };

  const navigationReactStyle = {
    top: topHatShown && !active ? TOPHAT_HEIGHT : 0,
    position: (topHatShown && !active) || !stickyMode ? 'absolute' : 'fixed',
  };
  const scrollMode = enableStickyModeAlways
    ? 'fixed'
    : stickyMode
    ? 'sticky'
    : 'static';
  const navigationStyle = classNames(
    'navigation',
    'cu-navigation',
    `navigation--${scrollMode}`,
    {
      'navigation--active': (active && stickyMode) || enableStickyModeAlways,
      'navigation--cta-always': cta_always,
      'navigation--device': isDevice,
      'navigation--minimal': enableMinimalNav,
      'navigation--tophat-shown': topHatShown,
    }
  );

  // Disable the global navigation bar on the PWA page if the unified Edna Login experience is enabled on this page.
  const isOnEdnaLoginRoute =
    featureFlags?.hasEdnaLoginOnPWAEnabled && asPath.match(/\/app.*/);

  return (
    <ThemeProvider theme={isTransparent ? oneHuluDarkTheme : oneHuluLightTheme}>
      {!isOnEdnaLoginRoute && (
        <GlobalNav
          logo={{
            href: '/',
            disableClick: disable_logo,
          }}
          className={navigationStyle}
          style={navigationReactStyle}
          editorialItems={getNavItems()}
          navEndItems={[
            ctaText && shouldRenderCta && (
              <CTA
                key="cta"
                size={isMobileView ? 'small' : 'large'}
                className={classNames('navigation__cta', {
                  'navigation__cta--transparent': isTransparent,
                  'navigation__cta--always-show': cta_always && stickyMode,
                  'navigation__cta--active': ctaActive,
                  'navigation__cta--hidden':
                    active && isMobileView && enable_cta_toaster,
                })}
              />
            ),
            <Login key="login" onLoginButtonClicked={handleLoginButtonClick} />,
          ]}
          scrollMode={scrollMode}
          isTransparent={isTransparent}
          itemAlignment="left"
          useHamburgerOnMobile
          showItemsIfRoom={false}
        />
      )}
      {enable_cta_toaster && ctaText && (
        <Toaster active={ctaActive && !positionBottom} mobileOnly>
          <div className="navigation__toaster-scrim">
            <CTA className="navigation__toaster-cta" />
          </div>
        </Toaster>
      )}
      {pageType !== CMS_PAGETYPE.micro && !isLoggedIn && (
        <LoginModal
          hasUnifiedLoginEnabled={featureFlags?.hasUnifiedLoginEnabled ?? false}
          hasUpdatedGenderOptions={
            featureFlags?.hasUpdatedGenderOptions ?? false
          }
        />
      )}
    </ThemeProvider>
  );
};

Navigation.propTypes = {
  cartAbandonment: PropTypes.shape({}),
  ctaFields: PropTypes.shape({}),
  locale: PropTypes.string,
  model: NavigationModelSchema.isRequired,
  pageType: PropTypes.string,
  toggleModal: PropTypes.func,
  topHatShown: PropTypes.bool,
  user: PropTypes.shape({}),
  ...DetailEntityStoreSchema,
};

export default Navigation;
