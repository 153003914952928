// TODO: Legacy login replace with WebLogin #HUWEB-30424
import { PWALoginPageModal } from '@hulu/login-modal';
import { WebLogin, QueryClientProvider } from '@hulu/web-login-ui';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { LoginFormModelSchema } from '../model/schema';

import { getHost } from '!app/lib/config';
import { USER_AGENTS_REGEX } from '!app/lib/constants';
import { mobileDetect } from '!app/lib/environment';
import { ComponentLogger } from '!app/lib/logger';
import { LoginAppRouter } from '!app/lib/utils/identityRouters';
import * as metricsTracker from '!app/metrics';
import '../stylesheet/LoginForm.scss';

const logMetaData = { logName: 'PWALoginForm' };

const ClientOnlyUnifiedLogin = dynamic(
  () => import('@hulu/web-login-ui').then((module) => module.UnifiedLogin),
  { ssr: false }
);

// Note: This component has integrations with PWALoginPageModal that will cause testing errors when
// converted to a functional component and tested with mount(). Shallow() can't be used because of
// componentDidMount to useEffect migration which will require mount() or a different testing solution.

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.publicRuntimeConfig = getConfig().publicRuntimeConfig;
    this.state = {
      signupHref: null,
    };
    // Show visible mode for these user agent match
    this.visibleMode = mobileDetect().match(
      `${USER_AGENTS_REGEX.TESLA}|${USER_AGENTS_REGEX.GM}`
    );

    this.onLoggedIn = this.onLoggedIn.bind(this);
  }

  componentDidMount() {
    const {
      model: { serviceworker },
    } = this.props;

    // Normally we want to avoid calling setState in componentDidMount, however,
    // we can't know whether the user is in the MS PWA until the code runs on the client.
    // Since display the other sign up link for legal reasons, it's not safe to put this
    // in the constructor

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      signupHref: global.Windows
        ? '/start/microsoft'
        : `${getHost('signup')}/go/one-hulu`,
    });

    if (!serviceworker || !('serviceWorker' in navigator)) return;

    navigator.serviceWorker
      .register(serviceworker, { scope: '/app/' })
      .catch((error) => {
        ComponentLogger.error(
          `Error with service worker, Error: ${error}`,
          logMetaData
        );
      });
  }

  onLoggedIn() {
    window.location.reload();
  }

  handleDoNotShareClick(e) {
    e.preventDefault();
    const activeGroups = window?.OnetrustActiveGroups || '';
    // Split by comma and filter out empty values
    const currentGroupCount = activeGroups.split(',').filter((group) => group)
      .length;

    window?.OneTrust?.OnConsentChanged((consentEvent) => {
      const newGroupCount = consentEvent?.detail?.length;
      // Only trigger a reload if active group count changed
      if (currentGroupCount !== newGroupCount) {
        window?.location?.reload();
      }
    });
    window?.OneTrust?.ToggleInfoDisplay();
  }

  getLoginScreen() {
    const { featureFlags } = this.props;

    const { signupHref } = this.state;

    const unifiedLoginConfig = {
      disableCreateAccount: false,
      createAccountPath: signupHref,
      enableReviewAndAcceptPage:
        featureFlags?.hasEdnaLoginReviewAndAcceptEnabled,
      router: LoginAppRouter().build(),
      trackEvent: metricsTracker?.trackEvent,
      env: this.publicRuntimeConfig?.webLoginEnv,
      recaptchaMode: 'visible',
      recaptchaConfigName: 'pwa_login',
    };

    const relTag = 'noopener noreferrer nofollow';

    const footerConfig = [
      {
        text: `© ${new Date().getFullYear()} Hulu, LLC`,
      },
      {
        text: 'About Ads',
        href: '//info.evidon.com/pub_info/3920?v=1&nt=0&nw=false',
        rel: relTag,
        target: '_blank',
      },
      {
        text: 'Subscriber Agreement',
        href: '/subscriber_agreement',
        rel: relTag,
        target: '_self',
      },
      {
        text: 'Privacy Policy',
        href:
          'https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/',
        rel: relTag,
        target: '_self',
      },
      {
        text: 'Do Not Sell or Share My Personal Information',
        rel: '',
        target: '_self',
        onClick: this.handleDoNotShareClick,
      },
      {
        text: 'Your US State Privacy Rights',
        href: '/ca-privacy-rights',
        rel: '',
        target: '_self',
      },
      {
        text: 'TV Parental Guidelines',
        href: 'http://www.tvguidelines.org',
        rel: relTag,
        target: '_blank',
      },
      {
        text: 'Sitemap',
        href: '/sitemap',
        rel: '',
        target: '_self',
      },
    ];

    if (featureFlags?.hasEdnaLoginOnPWAEnabled) {
      // The Edna Login feature component is designed to support both client-side or Next.js routing.
      // It's set to be client-only for now due to nuances of the existing routing setup in hitch
      // that also involves an upstream service (hookup).
      return (
        <QueryClientProvider>
          <ClientOnlyUnifiedLogin
            config={unifiedLoginConfig}
            onLoggedIn={this.onLoggedIn}
            footerConfig={footerConfig}
          />
        </QueryClientProvider>
      );
    }

    if (featureFlags?.hasUnifiedLoginEnabled) {
      return (
        <WebLogin
          env={this.publicRuntimeConfig?.webLoginEnv}
          recaptchaMode="visible"
          recaptchaConfigName="pwa_login"
          startTrialHref={signupHref}
          trackEvent={metricsTracker?.trackEvent}
          onLoggedIn={this.onLoggedIn}
        />
      );
    }

    return (
      <PWALoginPageModal
        config={this.publicRuntimeConfig}
        startTrialHref={signupHref}
        metricsTracker={metricsTracker}
        mobile
        forceFbLoginPopup
        visibleMode={this.visibleMode}
      />
    );
  }

  // LoginModal component's css is very fragile, it must be placed within
  // #login-modal modal-dialog, and that must have position set and a certain padding
  render() {
    // If the user is a user but not a subscriber, they can still get to this page
    // so we will hide the form in that case
    const {
      user: { isHuluUser },
      model: { manifest, nonsubWarning },
    } = this.props;

    const loginScreen = this.getLoginScreen();

    return (
      <div id="login-modal" className="LoginForm">
        {manifest && (
          <Head>
            <link rel="manifest" href={manifest} />
          </Head>
        )}
        {isHuluUser ? (
          <div
            className="LoginForm__dialog LoginForm__dialog--nonsub"
            dangerouslySetInnerHTML={{ __html: nonsubWarning }}
          />
        ) : (
          <div className="LoginForm__dialog modal-dialog">{loginScreen}</div>
        )}
      </div>
    );
  }
}

LoginForm.propTypes = {
  user: PropTypes.shape({}),
  model: LoginFormModelSchema,
  featureFlags: PropTypes.shape({}),
};

export default LoginForm;
